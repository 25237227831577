import React from "react";
import OurService from "../../components/Home/OurService";
import "./Services.css";

const Services = () => {
  return (
    <div>
      <div className="services-banner"></div>
      <div className="container">
        <OurService />
      </div>
    </div>
  );
};

export default Services;
