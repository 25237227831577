import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>
              <b>TECHNET</b>
            </h1>
            <p>Software Solutions Pvt. Ltd.</p>
            <ul className="footer-social">
              <li>
                <i className="fa-brands fa-facebook social"></i>
              </li>
              <li>
                <i className="fa-brands fa-instagram social"></i>
              </li>
              <li>
                <i className="fa-brands fa-twitter social"></i>
              </li>
              <li>
                <i className="fa-brands fa-linkedin social"></i>
              </li>
            </ul>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <h3>Company</h3>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <a href="#ourPartners">Partners</a>
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h3>Services</h3>
                <ul>
                  <li>Enterprise Networking</li>
                  <li>Network Security</li>
                  <li>Collaboration Solution</li>
                  <li>Audio Visual Solution</li>
                  <li>Server and storage</li>
                  <li>Structured Cabling</li>
                  <li>Security & Surveillance</li>
                  <li>Smart Class Solutions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <p>Copyright &#169; 2023 Technet Software Solutions Pvt. Ltd.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
