import React from "react";
import Carousel from "../../components/Home/Carousel";
import Partners from "../../components/Home/Partners";
import WhyWe from "../../components/Home/WhyWe";
import OurService from "../../components/Home/OurService";
import "./Home.css";

const Home = () => {
  return (
    <div>
      <Carousel />
      <WhyWe />
      <OurService />
      <Partners />
    </div>
  );
};

export default Home;
