import React from "react";
import Subcard from "../Subcard";
import "./index.css";

const Partners = () => {
  return (
    <div className="partner_section" id="ourPartners">
      <div className="container">
        <h2>
          <b className="partner_title">Our Trusted Partners</b>
        </h2>
        <div className="flex-container">
          <Subcard img={"assets/logos/cisco.svg"} />
          <Subcard img={"assets/logos/huawei.svg"} />
          <Subcard img={"assets/logos/aruba.svg"} />
          <Subcard img={"assets/logos/Sophos.svg"} />
          <Subcard img={"assets/logos/digisol.png"} />
          <Subcard img={"assets/logos/vivotek.png"} />
          <Subcard img={"assets/logos/Molex.svg"} />
          <Subcard img={"assets/logos/hikvison.svg"} />
          <Subcard img={"assets/logos/ruckus.svg"} />
          <Subcard img={"assets/logos/AlliedTelesis.svg"} />
          <Subcard img={"assets/logos/dr.png"} />
        </div>
      </div>
    </div>
  );
};

export default Partners;
