import React from "react";
import "./index.css";

const Carousel = () => {
  return (
    <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active c-item">
          <img
            src="assets/images/office1.jpg"
            className="d-block w-100 c-img"
            alt="Slide 1"
          />
          {/* <div className="carousel-caption">
            <h2>First slide label</h2>
            <p>Some representative placeholder content for the first slide.</p>
          </div> */}
        </div>
        <div className="carousel-item c-item">
          <img
            src="assets/images/office2.jpg"
            className="d-block w-100 c-img"
            alt="Slide 2"
          />
          {/* <div className="carousel-caption">
            <h2>Second slide label</h2>
            <p>Some representative placeholder content for the second slide.</p>
          </div> */}
        </div>
        <div className="carousel-item c-item">
          <img
            src="assets/images/office3.jpg"
            className="d-block w-100 c-img"
            alt="Slide 3"
          />
          {/* <div className="carousel-caption">
            <h2>Third slide label</h2>
            <p>Some representative placeholder content for the third slide.</p>
          </div> */}
        </div>
        <div className="carousel-item c-item">
          <img
            src="assets/images/office4.avif"
            className="d-block w-100 c-img"
            alt="Slide 4"
          />
          {/* <div className="carousel-caption">
            <h2>Forth slide label</h2>
            <p>Some representative placeholder content for the forth slide.</p>
          </div> */}
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#hero-carousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#hero-carousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
