import React from "react";
import "./index.css";

const OurService = () => {
  return (
    <div className="ourService container" id="ourServices">
      <div className="ourService_header">
        <h3>
          <b className="title">Our Service</b>
        </h3>
      </div>
      <div className="ourService_container">
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-ethernet"></i> Enterprise Networking
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Powering seamless connectivity and communication for businesses
              through robust and scalable network infrastructures.
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-shield-halved"></i> Network Security
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Ensuring the utmost protection and privacy of digital assets and
              communications through robust measures and protocols.
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-file-contract"></i> Collaboration
              Solution
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Streamline teamwork and boost productivity with our cutting-edge
              Collaboration Solution.
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-headset"></i> Audio Visual Solution
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Cutting-edge Audio Visual Solutions for immersive experiences and
              seamless communication.
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-cloud"></i> Server and storage
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Powerful and scalable computing resources and data storage
              solutions for seamless performance and data management.
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-network-wired"></i> Structured Cabling
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Comprehensive and organized network infrastructure to providing an
              efficient system for data, voice, and multimedia communication.
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-video"></i> Security & Surveillance
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Ensuring utmost safety with cutting-edge security and surveillance
              solutions.
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">
            <b>
              <i className="fa-solid fa-video"></i> Smart Class Solutions
            </b>
          </h5>
          <div className="card-body">
            <p className="card-text">
              Smart Classroom Solutions integrates interactive and rich
              multimedia equipment which illuminates abstract & difficult
              concepts with crystal clarity
            </p>
            {/* <a href="/" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
