import React from "react";
import "./Career.css";

const Career = () => {
  return (
    <div>
      <div className="career-banner"></div>
      <div className="container">
        <b
          style={{
            margin: "5rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Share your resume at info@technetsoftware.com
        </b>
      </div>
    </div>
  );
};

export default Career;
