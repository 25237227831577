import React from "react";
import Partners from "../../components/Home/Partners";
import "./About.css";
import "../common.css";

const About = () => {
  return (
    <div>
      <div className="about-banner"></div>
      <div className="container">
        <div className="box">
          <div className="title">
            <h2>
              <b>Who We Are?</b>
            </h2>
          </div>
          <div className="paragraph">
            <p align="justify">
              <strong>Technet Software Solutions Pvt. Ltd.</strong> a leading
              system integrator focusing on IT Infrastructure, Video
              Conferencing, Surveillance, Network Security and Customized IT
              Solutions. We offer services in the area of Network design &
              implementation for Data, Voice and Video enterprise integration.
            </p>
            <p align="justify">
              Incorporated in 1999 in New Delhi, India, Technet has presence all
              over India. We have successfully implemented challenging
              networking and systems integration projects for many prestigious
              customers. Our customer base includes Educational Institutes of
              repute, R & D Establishments and industry besides Government,
              PSUs, Banking, Insurance, Finance, Telecom, Manufacturing,
              Healthcare, Defence etc.
            </p>
            <p align="justify">
              Backed by VC funding Technet has a commitment to excellence in all
              its endeavours. We have leveraged a three-decade old expertise and
              experience in IT infrastructure which enables us to offer
              comprehensive IT solutions encompassing Networking, Hardware,
              Middleware, Systems Integration, and Security. We bring to our
              customers every component of IT infrastructure - servers, storage,
              networking and software products - through a host of alliances
              with technology leaders. These leading technology offerings are
              complemented by our all-round skills in planning, building,
              integrating and supporting your IT infrastructure. Our product
              portfolio has a comprehensive range of networking products,
              desktops, servers, thin clients, notebook computers and smart
              class solutions.
            </p>
            <p align="justify">
              We have vast experience over the entire gamut of networking
              classifications ranging from fixed, wireless, small, big,
              multi-location Networks covering hundreds of sites all over India
              including remote locations to very large concentric and convergent
              networks involving voice, data and image. Campus wide networks
              include Wi-Fi, Voice, CCTV and tens of miles of fibre optic
              cabling to Wide -Area-Networking anywhere in India.
            </p>
            <p align="justify">
              Our offices are located in New Delhi, Gurgaon, and Lucknow
              complemented by more than a dozen support locations all over
              India. Quality in the name. Trust in the relationship.
            </p>
          </div>
        </div>
      </div>
      <Partners />
    </div>
  );
};

export default About;
