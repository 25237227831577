import React from "react";
import "./index.css";

const WhyWe = () => {
  return (
    <div className="whywe">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <img src="assets/images/office.jpg" alt="office" />
              </div>
              <div className="col">
                <div className="whywe_header">
                  <h3>
                    <b className="title">Why choose us</b>
                  </h3>
                </div>
                <div>
                  <h4>
                    <i className="fa-solid fa-chart-column"></i> Boost Your
                    Business
                  </h4>
                  <p>
                    We are leading data, voice and security system company,
                    serving government and corporation customers with our
                    products and solutions.
                  </p>
                </div>
                <div>
                  <h4>
                    <i className="fa-solid fa-user-check"></i> Single window
                    solution
                  </h4>
                  <p>
                    A seamless, all-in-one company offering comprehensive
                    solutions to meet all your needs.
                  </p>
                </div>
                <div>
                  <h4>
                    <i className="fa-solid fa-headset"></i> 24/7 Support
                  </h4>
                  <p>
                    An efficient and on time support to our valuable customer is
                    our prime concern.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyWe;
