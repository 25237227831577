import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";

const Header = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div className="header">
      <div className="header-title">
        <h1 className="header-brand">
          <img
            className="header-img"
            src="assets/images/logo2.png"
            alt="Logo"
            onClick={navigateToHome}
          />
        </h1>
      </div>
      <input type="checkbox" id="toggler" />
      <label htmlFor="toggler">
        <i className="fa-solid fa-bars barIcon"></i>
      </label>
      <div className="menu">
        <ul className="list">
          <li>
            <b>
              <Link to="/">Home</Link>
            </b>
          </li>
          <li>
            <b>
              <Link to="/services">Services</Link>
            </b>
          </li>
          <li>
            <b>
              <Link to="/about">About Us</Link>
            </b>
          </li>
          <li>
            <b>
              <Link to="/career">Careers</Link>
            </b>
          </li>
          <li>
            <b>
              <Link to="/contact">Contact Us</Link>
            </b>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
