import React from "react";
import "./subcard.css";

const Subcard = (props) => {
  return (
    <div className="card shadow-lg cardSize">
      <img
        src={props.img}
        className="card-img-top"
        alt="..."
        style={{ width: "10rem", height: "6rem", alignSelf: "center" }}
      />
    </div>
  );
};

export default Subcard;
