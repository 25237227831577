import React from "react";
// import Form from "../../components/Contact/Form";
import Banner from "../../components/Contact/Banner";
import "../common.css";
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <Banner />
      <div className="container">
        <div className="box">
          <div className="title">
            <h2>
              <b>Contact Us</b>
            </h2>
          </div>
          <div className="content">
            <div>
              <p>
                <i className="fa-solid fa-map-location-dot"></i>
                <b> Registered Office: </b>
              </p>
              <ul>
                <li>
                  <p align="justify">Technet Software Solutions Pvt. Ltd.</p>
                  <p align="justify">35A/1, 2nd Floor, Shahpur Jat</p>
                  <p align="justify">New Delhi-110049</p>
                  <p align="justify">
                    <strong>Phone:</strong> 09958202047
                  </p>
                  <p align="justify">
                    <strong>Email:</strong> info@technetsoftware.com
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <p>
                <i className="fa-solid fa-map-location-dot"></i>
                <b> Branch Office: </b>
              </p>
              <ul>
                <li>
                  <p align="justify">Technet Software Solutions Pvt. Ltd.</p>
                  <p align="justify">Shroti Business Centre</p>
                  <p align="justify">10, Mandir Marg, Mahanagar Extension</p>
                  <p align="justify">Lucknow - 226006</p>
                  <p align="justify">
                    <strong>Phone:</strong> 09839074722
                  </p>
                  <p align="justify">
                    <strong>Email:</strong> info@technetsoftware.com
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <Form /> */}
      </div>
    </div>
  );
};

export default Contact;
